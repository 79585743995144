
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
class VsLoadingModal extends Vue {
    @Prop({ type: Boolean, required: true })
    private loading!: boolean;
}

export default VsLoadingModal;
