
import { Component, Vue } from 'vue-property-decorator';
import { VsOption, VsConfig } from '@/components/layouts/vs-alert';
import failed from '@/components/illustrations/failed.vue';
import alert from '@/components/illustrations/alert.vue';
import error from '@/components/illustrations/error.vue';
import success from '@/components/illustrations/success.vue';
import coins from '@/components/illustrations/coins.vue';
import Lottie from '@/components/Lottie.vue';

@Component({ components: { failed, alert, success, coins, error, Lottie } })
class PAlert extends Vue {
    
    public options: VsOption[] | null = null;
    public config: VsConfig | null = null;

    public image: string = 'failed';
    public title: string = '';
    public message: string = '';
    public isOpened: boolean = false;

    public showAlert(title: string, message: string, options: VsOption[] | null = null, image: string = 'failed', config: VsConfig | null = null) {
        this.options = options;
        this.config = config;
        this.image = image;
        this.title = title;
        this.message = message;
        this.isOpened = true;
    }

    public closeAlert(): void {
        this.isOpened = false;
    }

    public loadAction(action?: () => void) {
        this.isOpened = false;
        setTimeout(() => {
            if(action != null) {
                action();
            }
        }, 200);
    }

    public canDisplayCloseAlert(): boolean {
        return this.config !== null && this.config.displayCloseButton === true;
    }
}

export default PAlert;
